import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next"
import { getAllRoomCheckinDetails, roomTypeRead, getUpdateRecord, getAllRoomStatus, getReservationNotify, getAllRoomTypes } from "../../../middleware/backent_helper";
import BlockModal from "components/Booking/block";
import CheckOutModal from "../../../components/Checkout/checkOutModal";
import UnBlockModal from "components/Booking/unblock";
import UpdateModal from "components/Booking/update";
import MaintenanceHistory from "components/History/maintenanceHistory";
import RoomHistory from "components/History/roomHistory";
import ReservationNotify from "components/Booking/reservation";

import { useNavigate } from 'react-router-dom';
import Reservation from "pages/Reservation/reservation";


const CheckIn = props => {

  document.title = "StayPro | Check In/Check Out"

  const navigate = useNavigate();
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  );
  const [showBlockModal, setBlockModal] = useState(false);
  const [ismodalopen, setIsmodalopen] = useState(false);
  const [showUnBlockModal, setUnBlockModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showMaintenanceHistoryModal, setShowMaintenanceHistoryModal] = useState(false);
  const [showRoomHistory, setShowRoomHistory] = useState(false);
  const [RoomsTypeList, setRoomsTypeList] = useState([]);
  const [RoomsList, setRoomsList] = useState([]);
  const [updateModalText, setUpdateModalText] = useState("");
  const [textUpdate, setTextUpdate] = useState("Not Ready");
  const [roomNo, setRoomNo] = useState();
  const [roomId, setRoomId] = useState();
  const [bookingId, setBookingId] = useState(null);
  const [historyId, setHistoryId] = useState(null);
  const [updateBookingId, setUpdateBookingId] = useState(null);
  const [cleaningStatus, setCleaningStatus] = useState('');
  const [roomStatus, setRoomStatus] = useState(0);
  const [selectedRoomId, setSelectedRoomId] = useState(null)
  const [notifyReservation, setNotifyReservation] = useState([])
  const [roomNoSearch, setRoomNoSearch] = useState('');

  const [cleaningStatusMap, setCleaningStatusMap] = useState({});

  useEffect(() => {
    getAll();
  }, []);

  const getAll = () => {
    // Get Room-Types
    getAllRoomTypes(dbName)
      .then((res) => {

        setRoomsTypeList(res.data.data);
      });
    // Get Rooms
    getAllRoomCheckinDetails(hid, dbName)
      .then((res) => {
        setRoomsList(res.data.data);
       
        // Get cleaning status for each room with RoomStatusId == 3


        const cleaningStatusPromises = res.data.data
          .filter(room => room.RoomStatusId === 3)
          .map(room => getUpdateRecord(hid, dbName, room.bookingId)
            .then(response => ({ bookingId: room.bookingId, cleaningStatus: response.data.data.Clean_status }))
          );
        Promise.all(cleaningStatusPromises)
          .then(cleaningStatusResults => {
            const cleaningStatusMap = cleaningStatusResults.reduce((map, result) => {
              map[result.bookingId] = result.cleaningStatus;
              return map;
            }, {});
            setCleaningStatusMap(cleaningStatusMap);
          })
          .catch(error => console.error(error));

      });
    // Room Status list
    getAllRoomStatus(dbName)
      .then((res) => {
        setRoomStatus(res.data.data);
      });
  }

  const [isRoomCheckedOut, setIsRoomCheckedOut] = useState(false);

  useEffect(() => {
    if (isRoomCheckedOut) {
      getUpdateRecord(hid, dbName, updateBookingId)
        .then(response => {
          setCleaningStatus(response.data.data.Clean_status);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [isRoomCheckedOut, updateBookingId]);


  useEffect(() => {

    getReservationNotify(hid, dbName)
      .then(response => {
        setNotifyReservation(response.data.data);

      })
      .catch(error => {
        console.error(error);
      });

  }, []);


  const handleBlockClick = (roomId) => {
    setBlockModal(true);
    setSelectedRoomId(roomId);
  };

  const handleBlockSubmit = (data) => {
    setBlockModal(false);
    setSelectedRoomId(null);
  };

  const handleHistoryClick = (data) => {

  };

  const handleUnBlockClick = (roomId) => {
    setSelectedRoomId(roomId);
    setUnBlockModal(true);
  };

  const handleUnBlockSubmit = (data) => {
    setUnBlockModal(false);
  };

  const handleOpenModal = (bookingId) => {
    setBookingId(bookingId);
    setIsmodalopen(true);
  };

  const handleMainHistoryModal = () => {
    setShowMaintenanceHistoryModal(true)
  };
  const handleRoomHistoryModal = (id) => {
    setHistoryId(id)
    setShowRoomHistory(true)
  };

  const handleUpdateModal = (roomNo, bookingId) => {
    setRoomNo(roomNo);
    setUpdateBookingId(bookingId);
    setShowUpdateModal(true);
  };

  const handleUpdateSubmit = (updateBookingId) => {
    if (updateBookingId) {
      getUpdateRecord(hid, dbName, updateBookingId)
        .then(response => {
          setCleaningStatus(response.data.data.Clean_status);
          console.log(cleaningStatus)
          getAll();
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const toggleModal = () => setIsmodalopen(false);

  const handleCheckInClick = (roomId) => {
    navigate(`/booking_CheckIn?roomId=${roomId}`);
  };


  return (
    <React.Fragment>
      <BlockModal
        show={showBlockModal}
        handleSubmit={handleBlockSubmit}
        onCloseClick={() => { setBlockModal(false), getAll(), setSelectedRoomId(null) }}
        roomId={selectedRoomId}
      />
      <UnBlockModal
        show={showUnBlockModal}
        handleSubmit={handleUnBlockSubmit}
        onCloseClick={() => { setUnBlockModal(false), getAll(), setSelectedRoomId(null) }}
        roomId={selectedRoomId}
        bookingId={bookingId}
      />
      <CheckOutModal ismodalopen={ismodalopen} toggleModal={toggleModal} bookingId={bookingId} />
      <UpdateModal
        show={showUpdateModal}
        onCloseClick={() => { getAll(), setShowUpdateModal(false) }}
        updateBookingId={updateBookingId}
        roomNo={roomNo}
        onStatus={handleUpdateSubmit}
      />
      <MaintenanceHistory
        show={showMaintenanceHistoryModal}
        onCloseClick={() => setShowMaintenanceHistoryModal(false)}
      />
      <RoomHistory
        show={showRoomHistory}
        bookingId={historyId}
        onCloseClick={() => setShowRoomHistory(false)}
      />


      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Bookings" breadcrumbItem="Check In - Check Out" />
          <Card>
            <CardBody className="d-flex flex-wrap justify-content-end align-items-center">
            
            <input
    type="text"
    placeholder="🔎 Search by Room No."
    value={roomNoSearch}
    onChange={(e) => setRoomNoSearch(e.target.value)}
    className="form-control w-25 search-input"
  />
   
              <span className=" mx-2 my-2 px-3 py-2"
                style={{
                  fontWeight: "600", color: "#fff", backgroundColor: roomStatus.length > 0 ? roomStatus[0]?.StatusColor : '#ddd',
                }}
              >
                🛏️ Vacant: {roomStatus.length > 0 && roomStatus[0]?.RoomCount}
              </span>
              <span className=" mx-2 my-2 px-3 py-2"
                style={{
                  fontWeight: "600", color: "#fff", backgroundColor: roomStatus.length > 1 ? roomStatus[1]?.StatusColor : '#ddd',
                }}
              >
                🔑 Occupied: {roomStatus.length > 0 && roomStatus[1]?.RoomCount}
              </span>
              <span className=" mx-2 my-2 px-3 py-2"
                style={{
                  fontWeight: "600", color: "#fff", backgroundColor: roomStatus.length > 2 ? roomStatus[2]?.StatusColor : '#ddd',
                }}
              >
               🧹 House Keeping: {roomStatus.length > 2 && roomStatus[2]?.RoomCount}
              </span>
              <span className=" mx-2 my-2 px-3 py-2"
                style={{
                  fontWeight: "600", color: "#fff", backgroundColor: roomStatus.length > 3 ? roomStatus[3]?.StatusColor : '#ddd',
                }}
              >
               🛠 Maintenance: {roomStatus.length > 3 && roomStatus[3]?.RoomCount}
              </span>

            </CardBody>
          </Card>

          {notifyReservation?.length > 0 && <ReservationNotify notifyReservation={notifyReservation} />}
          {RoomsTypeList && RoomsTypeList.map((roomType, typeIndex) => (
            <Card key={typeIndex} className="my-3">
              <CardBody>
                <h5 className="mb-4">{roomType.title.toUpperCase()} - {roomType.normalTariff}</h5>
                <Row>
                  {RoomsList && RoomsList.filter(rooms => rooms.title === roomType.title && 
          rooms.roomNo.toString().includes(roomNoSearch) ).map((rooms, roomIndex) => (
                    <Col xs="12" sm="6" md="4" lg="3" key={roomIndex} className="mb-2">
                      <Card
                        className="text-center p-2"
                        style={{
                          backgroundColor: rooms.colorCode,
                          color: 'white',
                          height: '88%',
                          maxWidth: '250px'
                        }}
                      >
                        <h1 className="mt-3">{rooms.roomNo}</h1>
                        <h5>
                          {rooms.RoomStatusId == 1 || rooms.RoomStatusId == 3 || rooms.RoomStatusId == 4
                            ? rooms.statusName
                            : (
                              <>
                                {rooms.guestName || rooms.statusName}
                                <h6 className="p-2"><i className="fas fa-solid fa-phone phone-color"></i> {rooms.mobileNo || ''} 📍{rooms.city || ''}</h6>
                              </>
                            )
                          }
                        </h5>
                        <h6>
                          {
                            rooms.RoomStatusId == 3 ? (
                              cleaningStatusMap[rooms.bookingId] === "Not Ready" ? 'Not Ready' :
                                cleaningStatusMap[rooms.bookingId] === 'Cleaning' ? 'Cleaning' :
                                  cleaningStatusMap[rooms.bookingId] === 'Cleaned' ? 'Cleaned' :
                                    ''
                            ) : ''
                          }
                        </h6>
                        <div className="d-flex justify-content-around mt-2">
                          {rooms.isCheckedIn === 1 ? (
                            <>
                              <button className="btn btn-success w-sm" onClick={() => handleOpenModal(rooms.bookingId)}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: '8px' }}></i> Check-out
                              </button>
                              <button className="btn btn-secondary w-sm" onClick={() => handleRoomHistoryModal(rooms.bookingId)}>
                                <i className="fas fa-history" style={{ marginRight: '8px' }}></i> History
                              </button>
                            </>
                          ) : rooms.RoomStatusId === 4 ? (
                            <>
                              <button className="btn btn-primary w-sm" onClick={() => handleUnBlockClick(rooms.roomId)}>
                                <i className="far fa-edit" style={{ marginRight: '8px' }}></i> UnBlock
                              </button>
                              <button className="btn btn-secondary w-sm" >
                                <i className="fas fa-history" style={{ marginRight: '8px' }}></i> History
                              </button>
                            </>
                          ) : rooms.RoomStatusId === 3 ? (
                            <>
                              <button className="btn btn-primary w-sm" onClick={() => handleUpdateModal(rooms.roomNo, rooms.bookingId)}>
                                <i className="far fa-edit" style={{ marginRight: '8px' }}></i> Update
                              </button>
                              <button className="btn btn-secondary w-sm" onClick={() => handleMainHistoryModal(rooms.bookingId)}>
                                <i className="fas fa-history" style={{ marginRight: '8px' }}></i> History
                              </button>
                            </>
                          ) : (
                            <>
                              <button className="btn btn-primary w-sm" onClick={() => handleCheckInClick(rooms.roomId)}>
                                <i className="fa fa-check" style={{ marginRight: '8px' }}></i> Check In
                              </button>
                              <button className="btn btn-secondary w-sm" onClick={() => handleBlockClick(rooms.roomId)}>
                                <i className="fa fa-ban" style={{ marginRight: '8px' }}></i> Block
                              </button>
                            </>
                          )}
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          ))}
        </Container>
      </div>
    </React.Fragment >
  )
}

export default withTranslation()(CheckIn)