import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import { getPrintConfig } from "../../middleware/backent_helper";


const PrintFooter = () => {
  const [printConfig, setPrintConfig] = useState({});

  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  );

    useEffect(() => {
      getPrintConfig(dbName).then((res) => {
        
        setPrintConfig(res.data.data[0]);
       
      });
    }, [dbName]);
  return (
    <Row
      className="signature-section"
      style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "8rem" }}
    >
      <Col lg="4" xs="4" style={{ textAlign: "center" }}>
        <p>Guest's Signature</p>
      </Col>
      <Col lg="4" xs="4" style={{ textAlign: "center" }}>
        <p>{printConfig.note}</p>
      </Col>
      <Col lg="4" xs="4" style={{ textAlign: "center" }}>
        {printConfig.sign_upload ? (
          <img
            src={printConfig.sign_upload}
            alt="Signature"
            style={{ maxWidth: "100px", maxHeight: "50px" }}
          />
        ) : (
          <p></p>
        )}
        <p>F.O. Signature</p>
      </Col>
    </Row>
  );
};

export default PrintFooter;
