import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalBody,
    Form,
    Row,
    Col,
    Label,
    Card,
    CardBody,
    CardHeader,
    FormGroup,
    Table,
    Input
} from "reactstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get_record_history, getRoomServiceData, getGuestChargeData, getAdvanceData, get_shiftRoom_history } from '../../middleware/backent_helper';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import '../../assets/scss/plan.scss'
import { handlePrint } from './historyPrint.js';

const RoomHistory = ({ show, onCloseClick, bookingId }) => {
    const [hid, setHid] = useState(() => {
        const storedHotelId = localStorage.getItem('hotelId');
        return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
    });
    const [dbName, setDbName] = useState(localStorage.getItem('dbName'));
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [guestDetails, setGuestDetails] = useState();
    const [shiftRoomDetails, setShiftRoomDetails] = useState();
    const [employeeList, setEmployeeList] = useState([]);
    const [roomServiceData, setRoomServiceData] = useState({});
    const [advanceData, setAdvanceData] = useState({});
    
    const [isRefund, setIsRefund] = useState(false);
    const [totalData, setTotalData] = useState(0);
    const [totalLessAdvance, setTotalLessAdvance] = useState(0);
    const [GuestCharge, setGuestCharges] = useState({});
    const [isShiftRoomDataLoaded, setIsShiftRoomDataLoaded] = useState(false);

    const navigate = useNavigate();

    const handleButtonClick = (path) => {
        navigate(`/manage_room_service/${bookingId}`);
    };

    useEffect(() => {
        setIsShiftRoomDataLoaded(false);
        if (bookingId) {
            get_record_history(hid, dbName, bookingId)
                .then((res) => {
                    setGuestDetails(res.data.data);
                });
            get_shiftRoom_history(hid, dbName, bookingId)
                .then((res) => {
                    if (res.data.error === "Booking not found in Change_Room table") {
                        setIsShiftRoomDataLoaded(false);
                    } else {
                        setShiftRoomDetails(res.data.data);
                        setIsShiftRoomDataLoaded(true);
                    }
                });
        }
    }, [bookingId]);

    const validation = useFormik({
        initialValues: {
            servicePersonId: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            servicePersonId: Yup.string().required("Please Choose service person"),
        }),
        onSubmit: (values) => {
            Get_record(values);
            validation.resetForm();
        }
    });

    const Get_record = (values) => {
        const servicePerson = values.servicePersonId;
        const FromDate = fromDate;
        const ToDate = toDate;

        get_record(dbName, servicePerson, FromDate, ToDate).then((res) => {
            setEmployeeList(res.data.data);
        });
    };



    const getDatesBetween = (checkInDate, graceTime) => {
        const dates = [];
        const checkInMoment = moment(checkInDate);
        const checkOutMoment = moment(); // Current date

        // Start from the check-in date
        let currentDate = checkInMoment.clone();

        // Loop through each date until the day before the current date
        while (currentDate.isBefore(checkOutMoment, 'day')) {
            // Add all dates from check-in date up to but not including the current date
            dates.push(currentDate.format('YYYY-MM-DD HH:mm:ss'));
            currentDate.add(1, 'days');
        }

        // Now check if the current date meets the condition
        const lastAddedDate = moment(dates[dates.length - 1]);
        const graceTimeInMilliseconds = graceTime * 60 * 60 * 1000; // Convert grace time to milliseconds

        // Check if the current date exceeds last added date by 24 hours + grace time
        if (moment().diff(lastAddedDate, 'milliseconds') >= (24 * 60 * 60 * 1000 + graceTimeInMilliseconds)) {
            dates.push(checkOutMoment.format('YYYY-MM-DD HH:mm:ss')); // Add the current date if the condition is satisfied
        }

        return dates;
    };


    const checkInDate = guestDetails?.CheckInDate;
    const graceTime = guestDetails?.GraceTime;

    const datesBetween = getDatesBetween(checkInDate, graceTime);
 


    useEffect(() => {
        const promises = datesBetween.map((date) => {
            const formattedDate = moment(date).format('YYYY-MM-DD'); // Format date for API call
            return getRoomServiceData(formattedDate, dbName, bookingId).then((response) => {
                return { [formattedDate]: response.data };
            });
        });
        Promise.all(promises).then((results) => {
            const roomServiceData = results.reduce((acc, current) => {
                return { ...acc, ...current };
            }, {});
            setRoomServiceData(roomServiceData);
        });

        const promisesAdvance = datesBetween.map((date) => {
            const formattedDate = moment(date).format('YYYY-MM-DD'); // Format date for API call
            return getAdvanceData(formattedDate, dbName, bookingId).then((response) => {
                
                return { [formattedDate]: response.data };
            });
        });
        Promise.all(promisesAdvance).then((results) => {
            const advanceData = results.reduce((acc, current) => {
                return { ...acc, ...current };
            }, {});
            setAdvanceData(advanceData);
        });
        const promisesGuestCharges = datesBetween.map((date) => {
            const formattedDate = moment(date).format('YYYY-MM-DD'); // Format date for API call
            return getGuestChargeData(formattedDate, dbName, bookingId).then((response) => {
                return { [formattedDate]: response.data };
            });
        });
        Promise.all(promisesGuestCharges).then((results) => {
            const guestChargesData = results.reduce((acc, current) => {
                return { ...acc, ...current };
            }, {});
            setGuestCharges(guestChargesData);

        });
    }, [guestDetails]);

    const [cumulativeGuestTariff, setCumulativeGuestTariff] = useState({});


    useEffect(() => {
        if (guestDetails && datesBetween.length > 0) {
            
            // Calculate total charges
            const totalCharges = datesBetween.reduce((acc, date, index) => {
                const formattedDate = moment(date).format('YYYY-MM-DD');
                if (index === datesBetween.length - 1) {
                    const roomRent = guestDetails.Tariff;
                    const roomRentGuestTariff = guestDetails.GuestTariff;
                    const guestTariffForDate = cumulativeGuestTariff[formattedDate] || 0;
                    const gst = guestDetails.Gst;
                    const totalRoomService = Object.values(roomServiceData).reduce((acc, currentValue) => acc + (currentValue.data.TotalCash || 0), 0);
                    const previousRoomRent = index > 0 ? guestDetails.Tariff * index : 0;
                    const previousRoomRentGuestTariff = index > 0 ? guestDetails.GuestTariff * index : 0;
                  
                    const previousGst = index > 0 ? guestDetails.Gst * index : 0;
                    const lastCumulativeTariff = cumulativeGuestTariff[datesBetween[datesBetween.length - 1]] || 0;
                    return acc + roomRent + gst + totalRoomService + previousRoomRent + previousGst + lastCumulativeTariff + roomRentGuestTariff + previousRoomRentGuestTariff + guestTariffForDate;
                }
                return acc;
            }, 0);

            if (isShiftRoomDataLoaded) {

                setTotalData(totalCharges + shiftRoomDetails.GrossTotal);
            } else {
                setTotalData(totalCharges);
            }

            // Calculate total advance
            const totalAdvance = Object.values(advanceData).reduce((acc, currentValue) => {
                const advanceAmount = currentValue.data.AdvanceAmount;
                // Check if advanceAmount is a valid number
                if (advanceAmount > 0) {
                    return acc + advanceAmount;
                }

                return acc;
            }, 0);

            setTotalLessAdvance(totalAdvance);

            // Calculate balance
            const balance = totalCharges - totalAdvance;
            setIsRefund(totalAdvance > totalCharges); // Set isRefund based on whether total advances exceed total charges

        }
    }, [roomServiceData, advanceData, guestDetails, datesBetween, cumulativeGuestTariff]);

    useEffect(() => {
        let runningTotal = 0; // Initialize cumulative total
        let additionalTariff = 0; // Initialize additional tariff
        const cumulativeTariff = datesBetween.reduce((acc, date, index) => {
            const formattedDate = moment(date).format('YYYY-MM-DD');
            const currentGuestTariff = GuestCharge[formattedDate] ? GuestCharge[formattedDate].data.Guest_Tariff : 0;
            additionalTariff += currentGuestTariff; // Add currentGuestTariff to additionalTariff
            runningTotal += (guestDetails?.GuestTariff || 0) + additionalTariff; // Add guestTariff and additionalTariff to runningTotal
            acc[formattedDate] = runningTotal; // Set the cumulative total for the current date
            return acc;
        }, {});
        setCumulativeGuestTariff(cumulativeTariff); // Update state with cumulative values
    }, [GuestCharge]);

    const handlePrintClick = () => {
        handlePrint(totalData, totalLessAdvance, isRefund);
    };

    return (
        <React.Fragment>
            <Modal size="lg" isOpen={show} toggle={onCloseClick} centered={true}>
                <ModalBody className="px-4 py-5">
                    <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
                    <h5 className="mb-4">Guest History</h5>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >

                        {/* Guest Details Card */}
                        <Card className="mb-4 guest-details" >
                            <CardHeader className="grey-background text-black fw-bold">
                                <b>  Guest Details  </b>
                            </CardHeader>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col md={4}>
                                        <div><strong>Check-In:</strong> {guestDetails?.CheckInDate}</div>
                                    </Col>
                                    <Col md={4}>
                                        <div><strong>Check-Out:</strong> {moment().format('DD-MM-YYYY HH:mm:ss')}</div>
                                    </Col>
                                    <Col md={4}>
                                        <div><strong>Room Detail:</strong> {`${guestDetails?.RoomNo} (${guestDetails?.RoomType})`}</div>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={4}>
                                        <div><strong>Tariff:</strong> {guestDetails?.Tariff}</div>
                                    </Col>
                                    <Col md={4}>
                                        <div><strong>Name:</strong> {guestDetails?.Name}</div>
                                    </Col>
                                    <Col md={4}>
                                        <div><strong>Mobile:</strong> {guestDetails?.Mobile}</div>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={4}>
                                        <div><strong>Address:</strong> {guestDetails?.Address}</div>
                                    </Col>

                                    <Col md={4}>
                                        <div><strong>Guest Tariff</strong> {guestDetails?.Guest}
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                    <div>
                                        <strong>GRC No:</strong> {
                                        (() => {
                                            // Normalize dates to match advanceData keys
                                            const normalizedDates = datesBetween.map((dateTime) => dateTime.split(' ')[0]);

                                            // Extract GRCNo for each normalized date
                                            const grcNumbers = normalizedDates.map((date) => advanceData[date]?.data?.GRCNo || 0);

                                            // Filter non-zero GRCNo values
                                            const validGRCNumbers = grcNumbers.filter((num) => num !== 0);

                                            // Return the maximum GRCNo or fallback to 0
                                            return validGRCNumbers.length > 0 ? Math.max(...validGRCNumbers) : 0;
                                        })()
                                        }
                                    </div>
                                    </Col>

                                    </Row>
                                <Row>

                                    <Col md={4}>
                                        <div><strong>Pax (M+F+Kids):</strong> {`${guestDetails?.Male}+${guestDetails?.Female}+${guestDetails?.Kids}`}</div>
                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>

                        {isShiftRoomDataLoaded && (
                            <Card className="mb-4 shift-room-details">
                                <CardHeader className="grey-background text-black fw-bold">
                                    <b> Shift Room Details</b>
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col md={4}>
                                            <div><strong>Room No</strong> {shiftRoomDetails?.Room_No}</div>
                                        </Col>
                                        <Col md={4}>
                                            <div><strong>From Date:</strong>{shiftRoomDetails?.FromDate}</div>
                                        </Col>
                                        <Col md={4}>
                                            <div><strong>To Date</strong> {shiftRoomDetails?.ToDate}</div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={4}>
                                            <div><strong>Tariff:</strong> {shiftRoomDetails?.Tariff}</div>
                                        </Col>
                                        <Col md={4}>
                                            <div><strong>Advance:</strong> {shiftRoomDetails?.Advance}</div>
                                        </Col>
                                        <Col md={4}>
                                            <div><strong>Service charge:</strong> {shiftRoomDetails?.ServiceCharge}</div>
                                        </Col>

                                    </Row>
                                    <Row className="mb-3">


                                        <Col md={4}>
                                            <div><strong>Extra Person Tariff:</strong>  {shiftRoomDetails?.ExtraPerson}
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div><strong>Total:</strong>  {shiftRoomDetails?.Total}
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div><strong>Gross Total:</strong>  {shiftRoomDetails?.GrossTotal}
                                            </div>
                                        </Col>

                                    </Row>

                                </CardBody>
                            </Card>

                        )}
                        {/* Transaction Details Card */}
                        <Card className="mb-4 transaction-details">
                            <CardHeader className="grey-background text-black fw-bold">
                                <b> Transaction Details </b>
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th className="text-center">Date</th>
                                            <th className="text-center">Room Rent</th>
                                            <th className="text-center">Guest Charges</th>
                                            <th className="text-center">GST</th>
                                            <th className="text-center">Room Service</th>
                                            <th className="text-center">Less Advance</th>
                                            <th className="text-center">Method of payment</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datesBetween.map((date, index) => {
                                            const formattedDate = moment(date).format('YYYY-MM-DD');
                                            return (
                                                <tr key={index}>
                                                    <td className="text-center">{moment(date).format('DD-MM-YYYY')}</td>
                                                    <td className="text-center">
                                                        {index === 0 ? guestDetails?.Tariff : (guestDetails?.Tariff * (index + 1))}
                                                    </td>
                                                    <td className="text-center" key={index}>
                                                        {cumulativeGuestTariff[formattedDate] || 0}
                                                    </td>
                                                    <td className="text-center">
                                                        {index === 0 ? guestDetails?.Gst : (guestDetails?.Gst * (index + 1))}
                                                    </td>
                                                    <td className="text-center">
                                                        {roomServiceData[formattedDate] ? roomServiceData[formattedDate].data.TotalCash : 0}
                                                    </td>
                                                    <td className="text-center">
                                                        {advanceData[formattedDate] ? advanceData[formattedDate].data.AdvanceAmount : 0}
                                                    </td>
                                                    <td className="text-center">
                                                        {advanceData[formattedDate] ? advanceData[formattedDate].data.paymentType : 0}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>

                        {/* Payment Details Card */}
                        <Card>
                            <CardHeader className="grey-background text-black fw-bold">
                                Payment Details
                            </CardHeader>
                            <CardBody>
                                <Row className="mb-2">
                                    <Col md={3}>
                                        <div><strong>Total:</strong> {totalData}</div>
                                    </Col>
                                    <Col md={3}>
                                        <div><strong>Balance:</strong> {Math.round(Math.abs(totalData - totalLessAdvance))}</div>
                                    </Col>

                                    <Col md={2}>
                                        <div><strong>Amount:</strong></div>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    name="amountType"
                                                    value="Recieved"
                                                    checked={!isRefund}
                                                    onChange={() => setIsRefund(false)}
                                                />
                                                Received
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    name="amountType"
                                                    value="Refund"
                                                    checked={isRefund}
                                                    onChange={() => setIsRefund(true)}
                                                />
                                                Refund
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        {/* Button Row */}
                        <Row className="mt-3">
                            <Col className="col-1 mx-4">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleButtonClick('/manage_room_service')}
                                >
                                    Service
                                </button>
                            </Col>
                            <Col className="col-2 mx-0">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={handlePrintClick}
                                >
                                    Print
                                </button>
                            </Col>
                        </Row>

                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default RoomHistory;
