

export const Api_url = "/api"

//LOGIN
export const POST_LOGIN = `${Api_url}/auth/login`
export const SOCIAL_LOGIN = "/social-login";

//logoutLogdetails
export const POST_LOGOUT_LOGS = `${Api_url}/auth/logout`


// JWT TOKEN
export const VERIFY_TOKEN = `${Api_url}/auth/verifyJwtToken`
//REGISTER
export const POST_REGISTER = `${Api_url}/hotel/register`

//MODULE
export const ADD_MODULES = `${Api_url}/modules/`
export const GET_MODULES = `${Api_url}/modules/getModule`
export const UPDATE_MODULES = `${Api_url}/modules/updateModule`
export const DELETE_MODULES = "/api/modules/deleteModule";

// Hotels
export const GET_ALL_HOTELS = `${Api_url}/hotel/getAllHotels`;
export const UPDATE_HOTEL = `${Api_url}/hotel/update_hotel`
export const GET_SPECIFIC_PLAN = `${Api_url}/plan/getSpecificPlan`
export const GET_MENU_ITEMS = `${Api_url}/modules/getmenuItems`
export const DELETE_HOTEL = `${Api_url}/hotel/delete`

//PlanMaster
export const PLAN_REGISTER = `${Api_url}/plan/PlanAdd`
export const GET_PLAN = `${Api_url}/plan/get_All_Plan`;
export const UPDATE_PLAN = `${Api_url}/plan/update_plan_data`
export const MODULES_GET = `${Api_url}/plan/get_module_name`;
export const DELETE_PLAN = "/api/plan/delete/";

//LabTestcategory
export const TEST_CATEGORY = `${Api_url}/test_category/Test_Category`
export const GET_CATEGORY_LIST = `${Api_url}/test_category`
export const UPDATE_CATEGORY = `${Api_url}/test_category/data`

//DELETE
export const DELETE_TESTCATEGORY = "/api/test_category/delete/";

//Activity Logs
export const GET_ACTIVITY_LOGS = `${Api_url}/activityLog`;


// Hotel Page
export const REGISTER_HOTEL = `${Api_url}/hotel/register`;
export const GET_STATE = `${Api_url}/states`
export const GET_CITY = `${Api_url}/cities`
export const GET_PLANS = `${Api_url}/plan/get_All_Plan`
export const GET_COUNTRIES = `${Api_url}/countries`
export const GET_ALL_TIMEZONE = `${Api_url}/countries/timeZone`
export const GET_HOTEL_USERDETAIL = `${Api_url}/hotel/get_hotel_user`
export const UPDATE_PASSWORD = `${Api_url}/hotel/updatePassword`
//newforgototp
export const FORGOTVERIFY = `${Api_url}/auth/forgotverify`;
export const FORGOT_PASSWORD = `${Api_url}/auth/forgot_password`;
//Registerotp
export const VERIFY = `${Api_url}/auth/verify`;
export const RESET_PASSWORD = `${Api_url}/auth/reset_password`;
//Users
export const GET_USERS = "/api/user/";
export const GET_MENU = "/api/menu";
export const GET_USER_PROFILE = "/user";
export const ADD_NEW_USER = "/add/user";
export const UPDATE_USER = `${Api_url}/user/edit/account`;
export const DELETE_USER = "/api/user/delete/";
export const CLEAR_TOKEN = `${Api_url}/auth/clearToken`;
// settings
// export const GET_ADMIN = `${Api_url}/hotel/getAllAdmin`;
// export const GET_USER = `${Api_url}/user/getAllUsers`

// master menus
export const GET_Menus = `${Api_url}/menu`;
export const ADD_NEW_Menus = `${Api_url}/menu`
export const PUT_Menu = `${Api_url}/menu/update`
export const DELETE_MENU = `${Api_url}/menu/delete`;
// export const GET_ADMIN = `${Api_url}/hotel/getAllAdmin`;

// Room Type master
export const GET_ALLROOM_TYPE = `${Api_url}/roomtype`;
export const ADD_NEW_ROOM_TYPE = `${Api_url}/roomtype`
export const UPDATE_ROOM_TYPE = `${Api_url}/roomtype/update`
export const DELETE_ROOM_TYPE = `${Api_url}/roomtype/delete`

//Room Master
export const CREATE_ROOM = `${Api_url}/room`
export const UPDATE_ROOM = `${Api_url}/room/updateRoom`
export const GET_ROOM_TYPE = `${Api_url}/room/getRoomType`
export const GET_ROOMS = `${Api_url}/room/getRoom`
export const DELETE_ROOMS = `${Api_url}/room/deleteRoom`
// export const GET_ADMIN = `${Api_url}/hotel/getAllHotels`;
export const GET_USER = `${Api_url}/user/getAllUsers`

//Rooms in RoomType Mapping Api
export const ROOM_TYPE_ID_GET = `${Api_url}/room/get_RoomType_ID`;
export const ROOM_ID_GET = `${Api_url}/room/get_Room_ID`;
export const ADD_room_mapping = `${Api_url}/room/add_room_mapping`;
export const GET_room_mapping = `${Api_url}/room/get-room-map`;
export const GET_ROOM_TYPES_DETAILS = `${Api_url}/room/get_RoomType_Details`;
export const GET_ROOM_DETAILS = `${Api_url}/room/Edit_get_room_ID`;
export const UPDATE_ROOM_MAPS = `${Api_url}/room/update_room_map`;
export const DELETE_ROOM_MAP = `${Api_url}/room/delete`;

//Room Service Master
export const CREATE_ROOM_SERVICE = `${Api_url}/roomService/`
export const UPDATE_ROOM_SERVICE = `${Api_url}/roomService/updateRoomService`
export const GET_ROOM_SERVICE = `${Api_url}/roomService/getRoomService`
export const DELETE_ROOMS_SERVICE = `${Api_url}/roomService/deleteRoomService`

//Category Master
export const CREATE_CATEGORY = `${Api_url}/category`
export const UPDATE__CATEGORY = `${Api_url}/category/updateCategory`
export const GET_CATEGORY = `${Api_url}/category/getCategory`
export const DELETE_CATEGORY = `${Api_url}/category/deleteCategory`

//Inventry Master
export const CREATE_PURCHASE = `${Api_url}/purchase/`
export const UPDATE_PURCHASE = `${Api_url}/purchase/updatePurchase`
export const UPDATE_PURCHASE_EXISTING = `${Api_url}/purchase/updatePurchaseExisting`
export const GET_PURCHASE = `${Api_url}/purchase/getPurchase`
export const DELETE_PURCHASE = `${Api_url}/purchase/deletePurchase`

// Payment Types
export const CREATE_PAYMENT_TYPE = `${Api_url}/paymentType/create_payment_type`
export const GET_PAYMENT_TYPES = `${Api_url}/paymentType/get_paymentTypes`
export const DELETE_PAYMENT_TYPE = `${Api_url}/paymentType/delete_payment_type`

//Tax Master
export const ADD_NEW_TAX = `${Api_url}/tax/tax`
export const GET_TAX = `${Api_url}/tax/getTax`
export const UPDATE_TAX = `${Api_url}/tax/updateTax`
export const DELETE_TAX = `${Api_url}/tax/delete`

//Identity Master
export const ADD_NEW_IDENTITY = `${Api_url}/identity/Add_identity`
export const GET_IDENTITY = `${Api_url}/identity/get_identity`
export const UPDATE_IDENTITY = `${Api_url}/identity/update_identity`
export const DELETE_IDENTITY = `${Api_url}/identity/delete`

// Assets Master
export const CREATE_ASSETS = `${Api_url}/assets/create_assets`
export const GET_ASSETS_LIST = `${Api_url}/assets/get_assets_list`
export const DELETE_ASSET = `${Api_url}/assets/delete_asset`
export const EDIT_ASSETS = `${Api_url}/assets/edit_asset`

//Eb Account master
export const ADD_ELECTRICITY_ACCOUNT = `${Api_url}/electricity_account/Add_electricity_account`
export const GET_ELECTRICITY_ACCOUNT = `${Api_url}/electricity_account/get_electricity_account`
export const UPDATE_ELECTRICITY_ACCOUNT = `${Api_url}/electricity_account/update_electricity_account`
export const DELETE_ELECTRICITY_ACCOUNT = `${Api_url}/electricity_account/delete`

//Genset master
export const ADD_GENSET_DETAILS = `${Api_url}/genset/Add_genset_details`
export const GET_GENSET = `${Api_url}/genset/get_genset`

//Online portal master
export const ADD_ONLINE_PORTAL_TYPE = `${Api_url}/online_portal/Add_Online_Portal_Type`
export const GET_PORTAL_TYPE = `${Api_url}/online_portal/get_Portal_Type`
export const UPDATE_PORTAL_TYPE = `${Api_url}/online_portal/update_Portal_Type`
export const DELETE_PORTAL_TYPE = `${Api_url}/online_portal/delete`

// Customer Master
export const GET_ALL_CUSTOMER = `${Api_url}/customer/get_customers`
export const ADD_CUSTOMER = `${Api_url}/customer/add_customer`
export const DELETE_CUSTOMER = `${Api_url}/customer/delete_customer`
export const EDIT_CUSTOMER = `${Api_url}/customer/update_customer`
export const DELETE_CUSTOMER_IMAGE = `${Api_url}/customer/delete_customer_image`
export const GET_CUSTOMER_DETAILS = `${Api_url}/customer/get_customers_details`

// Income and Expenses
// Amount to Owner
export const ADD_AMOUNT_TO_OWNER = data => post(url.ADD_AMOUNT_TO_OWNER, data);
export const GET_ALL_AMOUNT = () => get(url.GET_ALL_AMOUNT);
export const DELETE_AMOUNT_DATA = data => del(url.DELETE_AMOUNT_DATA, data);
export const EDIT_AMOUNT_TO_OWNER = data => put(url.EDIT_AMOUNT_TO_OWNER, data);
// ALL Rooms Sales
export const GET_ROOM_SALES_BASED_ON_DATE = `${Api_url}/roomSalesReport/getAllSalesReportsBasedOnDate`
//Inventry Stock
export const CREATE_INVENTORY = `${Api_url}/inventoryStock/`
export const UPDATE_INVENTORY = `${Api_url}/inventoryStock/updateInventoryStock`
export const GET_INVENTORY = `${Api_url}/inventoryStock/getInventoryStock`
export const DELETE_INVENTORY = `${Api_url}/inventoryStock/deleteInventoryStock`

//settings
export const GET_HOTEL = `${Api_url}/hotel/get_hotel`
export const UPDATE_SETTINGS = `${Api_url}/hotel/update_settings`
//Inventry Sale
export const CREATE_INVENTORY_SALE = `${Api_url}/inventorySale/`
export const UPDATE_INVENTORY_SALE = `${Api_url}/inventorySale/updateInventorySale`
export const SAVE_SERVICE_INVENTORY = `${Api_url}/inventorySale/saveServiceInventory`
export const GET_INVENTORY_SALE = `${Api_url}/inventorySale/getInventorySales`
export const GET_SALES_DETAILS = `${Api_url}/inventorySale/getSalesDetails`
export const DELETE_INVENTORY_SALE = `${Api_url}/inventorySale/deleteInventorySale`
// petty cash
export const CREATE_PETTY_CASH = `${Api_url}/income/pettycash`
export const GET_PETTY_CASH = `${Api_url}/income/pettycash`
// OverAll PettyCash Report
export const GET_ALL_PETTYCASH = `${Api_url}/income/pettycash/getAllPettyCash`
export const GET_OVERALL_PETTY_CASH_REPORT = `${Api_url}/income/pettycash/getPettyCashBasedonMonth`

// Reservation
export const CREATE_RESERVATION = `${Api_url}/reservation/createReservation`
export const GET_ALL_ROOMS_NOT_CHECKIN = `${Api_url}/reservation/getRoomNotCheckIn`
export const GET_RESERVATION = `${Api_url}/reservation/getAllReservation`

// BOOKING DASHBOARD
export const GET_ALL_ROOMS_CHECKIN_DETAILS = `${Api_url}/booking/checkin/getAllRoomCheckinDetails`
export const GET_ALL_ROOMTYPE_DETAILS = `${Api_url}/booking/checkin/getRoomTypeDetails`
export const GET_ALL_ROOMTYPE = `${Api_url}/booking/checkin/getAllRoomTypes`
//Booking Checkin form
export const ADD_BOOKING_CHECK_IN = `${Api_url}/booking/checkin/checkinGuest`
export const GET_ROOMS_DETAILS = `${Api_url}/booking/checkin/getRoomDetails`
export const GET_ROOMS_TYPES = `${Api_url}/booking/checkin/getRoomTypes`
export const GET_WITHOUT_CHECKIN_ROOMS = `${Api_url}/booking/checkin/getRoomWithoutCheckIn`
export const ADD_BLOCK = `${Api_url}/block/createBlock`
export const PUT_BLOCK = `${Api_url}/block/updateBlock`
export const GET_MOBILENO_DETAILS = `${Api_url}/booking/checkin/getMobileNoDetails`
export const GUESTMOBILENO_DETAILS = `${Api_url}/booking/checkin/getGuestMobileNoDetails`
// BOOKING CHECK_OUT FORM
export const GET_CHECKOUT_DETAILS = `${Api_url}/booking/checkout/getCheckOutDetails`
export const CHECKOUT_GUEST = `${Api_url}/booking/checkout/checkoutGuest`
export const CHECKOUT_SERVICE = `${Api_url}/booking/checkout/getoomService`
// Booking ADVANCE FORM
export const GET_ADVANCE_BY_ID = `${Api_url}/booking/advance/getAdvanceById`
export const POST_ADVANCE = `${Api_url}/booking/advance/addAdvance`
export const GET_GRC_NO_BY_ID = `${Api_url}/booking/advance`
export const GET_ADVANCE_DETAILS = `${Api_url}/booking/advance/getAdvancedetails`
//  Booking EXTRA GUEST FORM
export const GET_EXTRA_GUEST_BY_ID = `${Api_url}/booking/ExtraGuest/getExtraGuest`
export const POST_EXTRA_GUEST = `${Api_url}/booking/ExtraGuest/addextraGuest`

// RESERVATION
export const GET_ALL_RESERVATION = `${Api_url}/reservation/getAllReservation`
export const GET_RESERVATION_NOTIFY = `${Api_url}/reservation/getReservationNotify`
export const CANCEL_RESERVATION = `${Api_url}/reservation/cancelReservation`
//BOOKING SHIFT ROOM
export const GET_GUEST_DETAILS = `${Api_url}/booking/shiftRoom/getGuestDetails`
export const GET_WITHOUT_CHECKIN_ROOMS_TYPES = `${Api_url}/booking/shiftRoom/getWithoutCheckInRoomAndType`
export const ADD_CHANGE_ROOM = `${Api_url}/booking/shiftRoom/addChangeRoom`
export const GET_SAME_ROOMS_TYPES = `${Api_url}/booking/shiftRoom/getSameRoomAndType`
export const ROOM_SERVICE_GET = `${Api_url}/booking/shiftRoom/getroomservicedetails`

// Blocks
export const GET_BLOCKS_USING_BOOKINGID = `${Api_url}/block/getSpecificBlockId`
//update cleaning
export const UPDATE_CLEANING = `${Api_url}/update/Update_cleaning`
export const UPDATE_CLEANED = `${Api_url}/update/Update_cleaned`
export const GET_CLEANING = `${Api_url}/update/getCleaning`

//History
export const GET_RECORD_MAINTENANCE = `${Api_url}/history/maintenance_history`
export const GET_RECORD_HISTORY = `${Api_url}/history/room_history`
export const GET_SHIFTROOM_HISTORY = `${Api_url}/history/ShiftRoom_history`
export const GET_ROOM_SERVICE_DATE = `${Api_url}/history/room_Service_cost`
export const GET_ADVANCE_DATA = `${Api_url}/history/advance_data`
export const GET_GUEST_CHARGE = `${Api_url}/history/guest_data`

// Room Status Master
export const GET_ALL_ROOM_STATUS = `${Api_url}/room_status`

//COnvert Reservation 
export const GET_RESERVATION_RECORD = `${Api_url}/reservation/convert/getReservation`
export const GET_ROOM_NUMBERS = `${Api_url}/reservation/convert/getRoomNumbers`
export const GET_AVAILABLE_ROOM_CHECKIN = `${Api_url}/reservation/convert/getAvailableRoomCheckIn`
export const CONVERT_RESERVATION_CHECK_IN = `${Api_url}/reservation/convert/convertReservation`

//Print 
export const ADD_PRINT_STYLE = `${Api_url}/printConfig/printConfig`
export const GET_PRINT_CONFIG = `${Api_url}/printConfig/getPrintConfig`
export const GET_SIGN = `${Api_url}/printConfig/getSign`

