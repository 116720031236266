import React, { forwardRef, useEffect, useState, } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col } from "reactstrap";
import PrintHeader from "components/Common/printHeader";
import PrintFooter from "components/Common/printFooter";
import { useLocation } from "react-router-dom";
import '../../assets/scss/print.scss';
import { getPrintConfig, getAdvanceDetails, getHotelById } from "../../middleware/backent_helper";

const AdvanceReceiptPrint = forwardRef((props, ref) => {
    const location = useLocation();

    // Destructure values from location.state if it exists
    const {
        GuestDetails,
        advanceNo,
        totalAdvanceAmounts,
        currentAdvanceAmount,
        dateTime,
        roomId,
        guest_name
    } = location.state || {};

    const { guestName, advance, paymentType, roomNo, tariff, checkInDate } = GuestDetails || {};
    const [printConfig, setPrintConfig] = useState({});
    const [advanceDetails, setAdvanceDetails] = useState([]);

    const [hoteldetails, setHotel] = useState([]);
    const [hid, setHid] = useState(() => {
        const storedHotelId = localStorage.getItem('hotelId')
        return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
    });
    const [dbName, setDbName] = useState(
        localStorage.getItem('dbName')
    );

    useEffect(() => {
        getPrintConfig(dbName).then((res) => {
            setPrintConfig(res.data.data[0]);

        });
    }, [dbName]);

    useEffect(() => {
        getAdvanceDetails(hid, dbName, roomId)
            .then((res) => {
                // console.log()
                setAdvanceDetails(res.data.data[0]);
            });
    }, [dbName]);

    useEffect(() => {
        getHotelById(hid, dbName)
            .then((res) => {

                setHotel(res.data.data);
            });
    }, [])
    const printReceipt = () => {
        // Open a new blank window
        const printWindow = window.open('', '_blank');

        // Write HTML structure and styles to the print window
        printWindow.document.write(`
     <html>
            <head>
                <title>Print Receipt</title>
                <style>
                    @media print {
                        @page {
                            size: 3in auto; /* Set page width to 3 inches */
                            margin: 5m; /* Adjust margins */
                        }
                        body {
                            margin: 0;
                            padding: 5mm;
                            font-family: Arial, sans-serif;
                            font-size: 13px;
                            width: 3in;
                        }
                    }
                    /* Additional styles for the content */
                    .receipt-container1 {
                        width: 100%;
                        box-sizing: border-box;
                    }
                    .print-amount{
                      border: 2px solid black;
                      padding: 10px;
                      display: inline-block;
                      margin-top: 10px;
                      font-weight: bold;
                      text-align: end; 

                              }
                    .printbutton {
                        display: none;
                    }
                </style>
            </head>
            <body>
                <div class="receipt-container1">
                    ${document.querySelector('.receipt-container1').innerHTML}
                </div>
            </body>
        </html>
  `);

        printWindow.print();
        printWindow.document.close();
    };


    return (
        <div ref={ref} className="receipt-container1">
            <div className="receipt-content1">
                <h3 className="receipt-title" style={{ textAlign: "center" }}>
                    ROOM ADVANCE RECEIPT
                </h3>
            </div>
            <PrintHeader />

            {/* Body Content */}
            <Row className="receipt-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
                <Col lg="6" xs="6" sm="6" className="receipt-field-col" style={{ flexGrow: 1 }}>
                    <p className="receipt-field">
                        <strong>No:</strong> {advanceNo?.AdvanceId || advanceDetails?.AdvanceId || "N/A"}
                    </p>
                </Col>

                <Col lg="6" xs="6" sm="6" className="receipt-field-col" style={{ flexGrow: 1, textAlign: "end" }}>
                    <p className="receipt-field" style={{ whiteSpace: "nowrap", margin: 0 }}>
                        <strong>GRC No:</strong> {advanceNo?.GRCNo || advanceDetails?.GRCNo || "N/A"}
                    </p>
                </Col>
            </Row>
            <p className="receipt-field">
                <strong>Date & Time:</strong> {dateTime || advanceDetails.checkInDate || "N/A"}
            </p>
            <p>
                Received with thanks from Mr. {guestName || guest_name || "Guest"} the sum of Rupees {currentAdvanceAmount || advanceDetails.AdvanceAmount || "N/A"} as
                advance by {paymentType || "CASH"} for  {advanceDetails.roomType || "RoomType"}.
                <strong>{roomNo || advanceDetails.roomNo || "roomNo"}</strong>. Room Rent: {tariff || advanceDetails.tariff || "N/A"}, and total amount paid:{" "}
                <strong>{totalAdvanceAmounts || advanceDetails.tariff || "N/A"}.</strong>
            </p>
            <p>
                <strong>Date and Time of Arrival:</strong> {checkInDate || advanceDetails.checkInDate || "N/A"}
            </p>

            {/* Amount Paid Section */}

            <div style={{ textAlign: "right" }} >
                <p className="print-amount">
                    <strong>{hoteldetails?.currency}:</strong> {currentAdvanceAmount || advanceDetails.AdvanceAmount || "N/A"}
                </p>
            </div>

            {/* Rules and Regulations */}
            <h5>RULES & REGULATIONS</h5>
            <div
                dangerouslySetInnerHTML={{
                    __html: printConfig.declaration || "<p>No rules and regulations available.</p>",
                }}
            ></div>

            <div className="text-end">
                <button type="button" className=" printbutton btn btn-success w-md me-3 btn btn-secondary " onClick={printReceipt}><i className="action-icons mdi mdi-printer-settings"></i> Print</button>
            </div>

            <PrintFooter />
        </div>
    );
});

AdvanceReceiptPrint.propTypes = {
    GuestDetails: PropTypes.object,
    advanceNo: PropTypes.object,
    dateTime: PropTypes.string,
    totalAdvanceAmounts: PropTypes.number,
    currentAdvanceAmount: PropTypes.number,
    hoteldetails: PropTypes.object,
};

export default AdvanceReceiptPrint;
