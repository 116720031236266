import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input, Button, CardHeader, Form, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import BlockModal from "components/Booking/block";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from "react-redux";
import { roomTypeRead, getCountries, getStates, getCities, getIdentity, getPaymentTypes, getRooms, getWithoutCheckinRooms, getRoomsDetils, getRoomType, add_booking_checkin, GetMobilenoDetails, GetguestDetails ,getHotelById } from "../../../middleware/backent_helper";
import { useLocation } from 'react-router-dom';
import { resizeImage } from 'utils/imageUtils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { addDays } from 'date-fns';

const BookingCheckIn = () => {
  document.title = "StayPro | Check In Form";

  const [showBlockModal, setBlockModal] = useState(false);
  const [checkInDate, setCheckInDate] = useState(new Date());
  const [checkOutDate, setCheckOutDate] = useState(addDays(new Date(), 1));
  const [roomsDetails, setRoomsDetails] = useState('');

  const [selectedGender, setSelectedGender] = useState('');
  const [proofTypes, setProofTypes] = useState([]);

  const [selectedProofType, setselectedProofType] = useState('');
  const [selectedPayment, setselectedPayment] = useState('');
  const [paymentTypeList, setPaymentTypes] = useState([]);
  const [roomTypeList, setroomTypeList] = useState([]);

  const [roomsList, setRoomsList] = useState([]);
  const [selectedWifi, setSelectedWifi] = useState(null);
  const [selectedRoomType, setSelectedRoomType] = useState(null);
  const [selectedNationality, setSelectedNationality] = useState('Indian');
  const [countriesList, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState('');
  const [statesList, setStatesList] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [getstateKey, setstateKey] = useState([])
  const [customers, setCustomers] = useState([]);
  const [resizedImage, setResizedImage] = useState();
  const [showModal, setShowModal] = useState(false);
  const [mobileSearch, setMobileSearch] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [guestDetails, setGuestDetails] = useState(null);
  const [tariff, setTariff] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  //Available Room list
  const [RoomsTypeList, setRoomsTypeList] = useState([]);
  const [RoomsList, AsetRoomsList] = useState([]);
  const [hoteldetails, setHotel] = useState([]);
  const staticImageUrl = '/R.jpg';
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const handleBackClick = () => {

    navigate(`/booking`);
  };

  const handleCheckInDateChange = (date) => {
    setCheckInDate(date);
  };

  const handleCheckOutDateChange = (date) => {
    setCheckOutDate(date);
  };

  const handleProofTypeChange = selectedOption => {
    setselectedProofType(selectedOption);
    validation.setFieldValue('proofType', selectedOption ? selectedOption.value : '');

  }
  const billFormatComponents = {
    "/static/media/ShiftRoomAdvancePrint.6f93543ec9598b8c8812.png": "/AdvanceShiftRoomReceiptPrint",
    "/static/media/RoomAdvancePrint.6f93543ec9598b8c8812.png": "/AdvanceReceiptPrint",
    "/static/media/AdvancePrintThreeInch.a0069d08bbd09516e3b3.png": "/AdvanceReceiptThreeInchPrint",
  };

  const formatDate = (dateTime) => {
    const date = new Date(dateTime);
    if (isNaN(date)) {
      throw new Error("Invalid Date");
    }

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    getHotelById(hid, dbName)
      .then((res) => {

        setHotel(res.data.data);
      });
  }, [])

  const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' }
  ];
  const NationalityOptions = [
    { value: 'Indian', label: 'Indian' },
    { value: 'Others', label: 'Others' }
  ];

  const handleGenderChange = selectedOption => {
    setSelectedGender(selectedOption);
    validation.setFieldValue('gender', selectedOption ? selectedOption.value : '');
  };

  const handlePaymentTypeChange = selectedOption => {
    setselectedPayment(selectedOption)
    validation.setFieldValue('payment', selectedOption ? selectedOption.value : '');

  }

  useEffect(() => {
    getPaymentTypes(hid, dbName)
      .then((res) => {
        setPaymentTypes(res.data.data);
      });
  }, [])

  useEffect(() => {

    getIdentity(hid, dbName)
      .then((res) => {
        setProofTypes(res.data.data)
      })
  }, [])

  useEffect(() => {
    if (selectedCountry != null) {
      const fetchStates = async () => {
        try {
          const stateKey = getCountryKey(selectedCountry);
          getStates(stateKey).then((res) => {
            setStatesList(res.data.data);
            if (customers && customers.state) {
              const foundState = res.data.data.find(state => state.name === customers.state);
              if (foundState) {
                setSelectedState({ label: foundState.name, value: foundState });
                validation.setFieldValue('state', foundState.name);
              }
            }
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      fetchStates();
    }

  }, [selectedCountry])

  const getStateKey = (stateName) => {
    const state = statesList.find(s => s.name === stateName.label);
    return state ? state.name : null;
  };

  const getCountryKey = (CountryKey) => {
    const state = countriesList.find(s => s.name === CountryKey.label);
    return state ? state.iso2 : null;
  }

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    if (selectedOption && selectedOption.label) {
      validation.setFieldValue('country', selectedOption.label);
    } else {
      validation.setFieldValue('country', '');
    }
  };

  useEffect(() => {
    getCountries()
      .then((res) => {
        setCountries(res.data.data)

        if (customers && customers.country) {
          const foundCountry = res.data.data.find(country => country.name === customers.country);
          if (foundCountry) {
            setSelectedCountry({ label: foundCountry.name, value: foundCountry });
            validation.setFieldValue('country', foundCountry.name);
          }
        }
      })
  }, [])

  // Fetch city when a state is selected
  useEffect(() => {
    if (selectedState && selectedState.label) {
      const fetchCities = async () => {
        try {
          const stateKey = getStateKey(selectedState);
          setstateKey(stateKey)

          getCities(stateKey)
            .then((res) => {
              setCitiesList(res.data.data);
              if (customers && customers.city) {
                const foundCity = res.data.data.find(city => city.name === customers.city);
                if (foundCity) {
                  setSelectedCities({ label: foundCity.name, value: foundCity });
                  validation.setFieldValue('city', foundCity.name);
                }
              }
            })
        } catch (error) {
          console.error('Error fetching city:', error);
        }
      };
      fetchCities();
    }
  }, [selectedState]);


  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption)
    if (selectedOption && selectedOption.label) {
      validation.setFieldValue('state', selectedOption.label);
    } else {
      validation.setFieldValue('state', '');
    }
  }

  const handleCityChange = (selectedOption) => {
    setSelectedCities(selectedOption)
    if (selectedOption && selectedOption.label) {
      validation.setFieldValue('city', selectedOption.label);
    } else {
      validation.setFieldValue('city', '');
    }
  }




  useEffect(() => {
    getRooms(hid, dbName)
      .then((res) => {

        setRoomsList(res.data.data);
      });
  }, [])

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const roomId = queryParams.get('roomId');


  //roomId wise get
  useEffect(() => {
    getRoomsDetils(hid, dbName, roomId)
      .then((res) => {

        setRoomsDetails(res.data.data);
      });
  }, [])

  //roomid base RoomType get
  useEffect(() => {
    getRoomType(hid, dbName, roomId).then((res) => {

      setroomTypeList(res.data.data[0]?.Rooms || []);
    });
  }, [])

  //Available RoomTypes &rooms
  useEffect(() => {
    // Get Room-Types
    roomTypeRead(hid, dbName)
      .then((res) => {

        setRoomsTypeList(res.data.data);
      });
    // Get Rooms
    getWithoutCheckinRooms(hid, dbName)
      .then((res) => {

        AsetRoomsList(res.data.data);
      });
  }, []);


  const handleRoomSelect = async (rooms) => {
    try {

      const res = await getRoomType(hid, dbName, rooms.id);
      const fetchedRoomTypes = res.data.data[0]?.Rooms || [];
      setroomTypeList(fetchedRoomTypes);
      const selectedRoomType = fetchedRoomTypes.find(type => type.roomTypeId === rooms.roomTypeId);

      setRoomsDetails({
        roomTypeTitle: selectedRoomType ? selectedRoomType.title : '',
        normalTariff: selectedRoomType ? selectedRoomType.normalTariff : '',
        singleTariff: selectedRoomType ? selectedRoomType.singleTariff : '',
        extraPersonTariff: selectedRoomType ? selectedRoomType.extraPersonTariff : '',
        roomNo: rooms.roomNo,
        roomId: rooms.id
      });

      handleClose();

    } catch (error) {
      console.error("Error fetching room types:", error);
    }
  };

  useEffect(() => {

  }, [hid, dbName, roomTypeList]);



  const handleRoomChange = selectedOption => {

    setSelectedRoomType(selectedOption);


    if (selectedOption) {
      const selectedRoom = roomTypeList.find(type => type.roomTypeId === selectedOption.value);

      setRoomsDetails({
        roomTypeTitle: selectedRoom ? selectedRoom.title : '',
        normalTariff: selectedRoom ? selectedRoom.normalTariff : '',
        singleTariff: selectedRoom ? selectedRoom.singleTariff : '',
        extraPersonTariff: selectedRoom ? selectedRoom.extraPersonTariff : '',
        roomNo: selectedRoom ? selectedRoom.roomNo : '',
        roomId: selectedRoom ? selectedRoom.roomId : '',
      });

      validation.setFieldValue('roomTypeIds', selectedOption.value);

    }
  }

  useEffect(() => {

    const defaultRoomType = roomTypeList.find(type => type.isActive === 1);

    if (defaultRoomType) {
      setSelectedRoomType({ value: defaultRoomType.roomTypeId, label: defaultRoomType.title });
      setRoomsDetails({
        roomTypeTitle: defaultRoomType.title,
        normalTariff: defaultRoomType.normalTariff,
        singleTariff: defaultRoomType ? defaultRoomType.singleTariff : '',
        extraPersonTariff: defaultRoomType ? defaultRoomType.extraPersonTariff : '',
        roomNo: defaultRoomType.roomNo,
        roomId: defaultRoomType.roomId,
      });
      validation.setFieldValue('roomTypeIds', defaultRoomType.roomTypeId);
    }
  }, [roomTypeList]);



  const handlewifiChange = selectedOption => {
    setSelectedWifi(selectedOption);
    validation.setFieldValue('wifi', selectedOption ? selectedOption.value : '');
  };

  const handleNationality = selectedOption => {
    setSelectedNationality(selectedOption);
    validation.setFieldValue('nationality', selectedOption ? selectedOption.value : '');
  };


  // Function to handle file changes and create previews
  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    setSelectedFiles(selectedFiles);
    const files = Array.from(selectedFiles);
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif']; // Allowed file types
    let fileErrors = [];

    const previews = files.map((file) => {
      if (!validImageTypes.includes(file.type)) {
        fileErrors.push("Only image files are allowed.");
        return null;
      }
      if (file.size > 2 * 1024 * 1024) { // Check if file is larger than 2MB
        fileErrors.push("File size must be less than 2MB.");
        return null;
      }
      return URL.createObjectURL(file); // Create a preview URL for the image
    }).filter(preview => preview !== null);

    if (fileErrors.length > 0) {
      validation.setFieldError('proof_photo', fileErrors.join(' ')); // Set validation error in Formik
    } else {
      validation.setFieldValue('proof_photo', files);
      setFilePreviews(previews);
    }
  };

  useEffect(() => {
    return () => {
      filePreviews.forEach((preview) => URL.revokeObjectURL(preview));
    };
  }, [filePreviews]);


  useEffect(() => {

    if (guestDetails && guestDetails.gender) {

      const matchedGenderOption = genderOptions.find(
        (option) => option.value === guestDetails.gender
      );
      setSelectedGender(matchedGenderOption || null);
      validation.setFieldValue('gender', guestDetails.gender);
    }
  }, [guestDetails]);

  useEffect(() => {
    if (guestDetails && guestDetails.IdentitiesId) {
      // Find the corresponding proof type based on identitiesId
      const selectedProof = proofTypes.find(
        (proof) => proof.id === guestDetails.IdentitiesId

      );

      if (selectedProof) {
        setselectedProofType({
          label: selectedProof.identityName,
          value: selectedProof.id,
        });

        validation.setFieldValue('proofType', selectedProof.id);
      }
    }

  }, [guestDetails, proofTypes]);


  useEffect(() => {
    if (guestDetails && guestDetails.country && guestDetails.country) {
      const selectedCountry = countriesList.find(
        (country) => country.name === guestDetails.country
      );

      if (selectedCountry) {
        setSelectedCountry({
          label: selectedCountry.name,
          value: selectedCountry,
        });

        // Update the validation field value
        validation.setFieldValue('country', selectedCountry);
      }
    }
  }, [guestDetails, countriesList]);



  useEffect(() => {
    if (guestDetails && guestDetails.state && guestDetails.state) {
      const selectedState = statesList.find(
        (state) => state.name === guestDetails.state
      );

      if (selectedState) {
        setSelectedState({
          label: selectedState.name,
          value: selectedState,
        });

        // Update the validation field value
        validation.setFieldValue('state', selectedState);
      }
    }
  }, [guestDetails, statesList]);


  useEffect(() => {
    if (guestDetails && guestDetails.city && guestDetails.city) {
      const selectedCity = citiesList.find(
        (city) => city.name === guestDetails.city
      );

      if (selectedCity) {
        setSelectedCities({
          label: selectedCity.name,
          value: selectedCity,
        });

        validation.setFieldValue('city', selectedCity);
      }
    }
  }, [guestDetails, citiesList]);

  const validation = useFormik({
    initialValues: {
      gender: '',
      mobile_no: "",
      guest_name: "",
      age: "",
      email: "",
      address: "",
      country: "",
      no_of_male: "",
      no_of_female: "",
      no_of_child: "",
      childrenAges: [],
      nationality: "Indian",
      roomTypeIds: '',
      payment: '',
      proof_photo: [],
      proofType: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      guest_name: Yup.string().required("Please Enter Your Name"),
      gender: Yup.string().required('Gender is required'),
      mobile_no: Yup.string()
        .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
        .required('Please Enter Your Mobile No'),
      email: Yup.string().matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Please Enter Valid Email").required('Email is required'),
      age: Yup.string().matches(/^\d+$/, 'Invalid number format').required('Please Enter Your Age'),
      address: Yup.string().required('Please Enter Your Address').max(50, 'Address must not exceed 50 characters'),
      nationality: Yup.string().required('Nationality is required'),
      roomTypeIds: Yup.string().required('RoomType is required'),
      payment: Yup.string().required('Payment Type is required'),
      proofType: Yup.string().required('Proof Type is required'),
    }),
    onSubmit: (values) => {
      dispatch(post_Booking_checkin(values));
    }
  });



  const post_Booking_checkin = async (values) => {
    try {
      const formData = new FormData();

      // Append form values to FormData
      formData.append('GuestName', values.guest_name);
      formData.append('mobileNo', values.mobile_no);
      formData.append('gender', values.gender);
      formData.append('address', values.address);
      formData.append('country', values.country);
      formData.append('state', values.state);
      formData.append('city', values.city);
      formData.append('pincode', values.pincode);
      formData.append('nationality', values.nationality);
      formData.append('age', values.age);
      formData.append('email', values.email);
      formData.append('IdentitiesId', values.proofType);
      formData.append('identityNo', typeof values.proof_no === 'string' ? values.proof_no.toUpperCase() : '');
      formData.append('vehicleNo', typeof values.vehicle_no === 'string' ? values.vehicle_no.toUpperCase() : '');
      formData.append('gstNo', typeof values.gst_no === 'string' ? values.gst_no.toUpperCase() : '');
      formData.append('companyName', values.company_name);
      formData.append('checkInDate', checkInDate);
      formData.append('checkOutDate', checkOutDate);
      formData.append('roomId', roomsDetails.roomId);
      formData.append('roomNo', roomsDetails.roomNo);
      formData.append('roomTypeId', values.roomTypeIds);
      formData.append('no_of_male', values.no_of_male !== "" ? values.no_of_male : 0);
      formData.append('no_of_female', values.no_of_female !== "" ? values.no_of_female : 0);
      formData.append('no_of_child', values.no_of_child !== "" ? values.no_of_child : 0);
      formData.append('child_ages', values.childrenAges && values.childrenAges.length > 0 ? JSON.stringify(values.childrenAges) : '0');
      formData.append('tariff', values.normaltariff ? values.normaltariff : 0);
      formData.append('advanceAmount', values.advance_Amt ? values.advance_Amt : 0);
      formData.append('paymentTypeId', values.payment);
      formData.append('remarks', values.remarks ? values.remarks : null);
      formData.append('referedBy', values.reference_by ? values.reference_by : null);
      formData.append('hotelId', hid);
      formData.append('dbName', dbName);
      formData.append('isActive', 'true');

      Array.from(selectedFiles).forEach((file, index) => {
        formData.append("proof_photo_json", file);
      });

     

      const response = await add_booking_checkin(formData);

      if (response.data.success) {
        const SelectedBillFormat = billFormatComponents[hoteldetails.billFormat] || "/AdvanceReceiptPrint";

        // Navigate to selected bill format page
        navigate(SelectedBillFormat, {
          state: {
          roomId:roomsDetails.roomId,
          guest_name:values.guest_name
        }

         });
       // navigate('/booking');

      } else {

        console.error('Failed to create booking.');
      }
    } catch (error) {

      console.error('An error occurred:', error);
    }
  };


  const showCommonError = () => {
    // Check if the form has been touched and if no fields have a value
    const { no_of_male, no_of_female, no_of_child } = validation.values;
    const touchedFields = validation.touched.no_of_male && validation.touched.no_of_female && validation.touched.no_of_child;

    if (touchedFields && !no_of_male && !no_of_female && !no_of_child) {
      return 'Please enter a Gender Count .';
    }
    return '';
  };

  const handleMobileSearch = async (e) => {
    const re = /^[0-9\b]+$/;
    const value = e.target.value;

    if (value === "" || re.test(value)) {
      validation.handleChange(e);
      setMobileSearch(value);

      if (value.length >= 3) {

        try {
          GetMobilenoDetails(hid, dbName, value).then((res) => {


            setSuggestions(res.data.data);
          });

        } catch (error) {
          console.error("Error fetching mobile numbers:", error);
        }
      } else {
        setSuggestions([]); // Clear suggestions if less than 3 characters
      }
    }
  };



  const handleSuggestionClick = async (mobileNo) => {

    setMobileSearch(mobileNo);
    validation.setFieldValue('mobile_no', mobileNo ? mobileNo : '');

    // Set selected mobile number in the input
    setSuggestions([]); // Clear suggestions

    // Fetch guest details by the selected mobile number
    try {
      GetguestDetails(hid, dbName, mobileNo).then((res) => {
        console.log(res.data.data[0]);
        const data = res.data.data[0]
        setGuestDetails(data);


        validation.setFieldValue('guest_name', data.GuestName || '');
        validation.setFieldValue('email', data.email || '');
        validation.setFieldValue('age', data.age || '');
        validation.setFieldValue('address', data.address || '');
        validation.setFieldValue('proof_no', data.identityNo || '');
        validation.setFieldValue('vehicle_no', data.vehicleNo || '');
        validation.setFieldValue('gst_no', data.taxNumber || '');
        validation.setFieldValue('company_name', data.companyName || '');
        validation.setFieldValue('wifi', data.wifi || '');
        validation.setFieldValue('nationality', data.nationality || '');
        validation.setFieldValue('pincode', data.pincode || '');

    //       // Parse and handle proof_photo_json
    // if (data.proof_photo_json) {
    //   const proofPhotos = JSON.parse(data.proof_photo_json); // Parse the JSON string
    //   setFilePreviews(proofPhotos); // Set the file previews
    // }
      // Handle proof_photo_json to display images
      let proofPhotos = [];
      if (data.proof_photo_json) {
        try {
          // Parse the JSON string containing image URLs
          proofPhotos = JSON.parse(data.proof_photo_json).map(decodeURIComponent);
        } catch (error) {
          console.error('Error parsing proof_photo_json:', error);
        }
      }
  
      // Update file previews state to display the images
      setFilePreviews(proofPhotos);

      });

    } catch (error) {
      console.error("Error fetching guest details:", error);
    }
  };

  // Function to extract minimum value from the tariff range string
  const extractMinTariff = (tariffRange) => {
    if (!tariffRange) return 0;
    const rangeParts = tariffRange.split('-').map(part => parseInt(part.replace(/[^0-9]/g, ""), 10)); // Extract numbers from the string
    return rangeParts[0]; // Return the first value, which is the minimum
  };

  // Function to calculate the number of days between check-in and check-out dates
  const calculateDaysDifference = (checkInDate, checkOutDate) => {
    const checkIn = checkInDate;
    const checkOut = checkOutDate;

    // Calculate the difference in time (in milliseconds)
    const timeDifference = checkOut - checkIn;

    // Convert time difference to days (1000ms * 60s * 60min * 24hrs = 1 day)
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return dayDifference >= 1 ? dayDifference : 1; // Default to 1 if the difference is invalid (same day or negative difference)
  };





  useEffect(() => {
    // Function to calculate tariff with child age consideration
    const calculateTariff = (noOfMale, noOfFemale, childrenAges, checkInDate, checkOutDate) => {
      const totalGuests = noOfMale + noOfFemale + childrenAges.filter(age => age >= 14).length;
      let totalTariff = 0;

      // Extract minimum tariff from the range values
      const singleTariff = extractMinTariff(roomsDetails.singleTariff);
      const normalTariff = extractMinTariff(roomsDetails.normalTariff);
      const extraPersonTariff = extractMinTariff(roomsDetails.extraPersonTariff);
      const extraChildTariff = extractMinTariff(roomsDetails.extraPersonTariff);

      // Calculate the number of days between check-in and check-out
      const daysDifference = calculateDaysDifference(checkInDate, checkOutDate);

      // Determine the base tariff based on the number of guests
      if (totalGuests === 1) {
        totalTariff = singleTariff || 0; // Single Tariff for 1 guest
      } else if (totalGuests === 2) {
        totalTariff = normalTariff || 0; // Normal Tariff for 2 guests
      } else if (totalGuests > 2) {
        totalTariff = normalTariff + extraPersonTariff * (totalGuests - 2); // Extra Person Tariff for additional guests
      }

      totalTariff *= daysDifference;
      setTariff(totalTariff);
      validation.setFieldValue("normaltariff", totalTariff);
      validation.setFieldValue("advance_Amt", totalTariff);
    };
    const noOfMale = validation.values.no_of_male || 0;
    const noOfFemale = validation.values.no_of_female || 0;
    const childrenAges = validation.values.childrenAges || [];

    if (checkInDate && checkOutDate) {
      calculateTariff(Number(noOfMale), Number(noOfFemale), childrenAges, checkInDate, checkOutDate);
    }
  }, [
    validation.values.no_of_male,
    validation.values.no_of_female,
    validation.values.no_of_child,
    validation.values.childrenAges,
    checkInDate,
    checkOutDate,
    roomsDetails
  ]);

  // Function to dynamically render child age inputs
  const renderChildrenAges = () => {
    return Array.from({ length: validation.values.no_of_child }).map((_, i) => (
      <div key={i} className="mb-3">
        <label className="form-label" htmlFor={`childrenAges[${i}]`}>
          Child {i + 1} Age
        </label>
        <Input
          type="number"
          name={`childrenAges[${i}]`}
          placeholder="Enter age"
          value={
            validation.values.childrenAges && validation.values.childrenAges[i] !== undefined
              ? validation.values.childrenAges[i]
              : ""
          }
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          min={0}
          invalid={
            validation.touched.childrenAges &&
            validation.touched.childrenAges[i] &&
            validation.errors.childrenAges &&
            validation.errors.childrenAges[i]
          }
        />
        {validation.errors.childrenAges && validation.errors.childrenAges[i] && (
          <FormFeedback>{validation.errors.childrenAges[i]}</FormFeedback>
        )}
      </div>
    ));
  };

  return (
    <React.Fragment>
      <BlockModal
        show={showBlockModal}
        handleSubmit={() => {/* handle submit */ }}
        onCloseClick={() => setBlockModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Bookings" breadcrumbItem="Booking-CheckIn" />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
          
    <Modal isOpen={showModal} toggle={handleClose} className="modal-dialog-centered custom-modal1 right-bar big" id="right-bar">
  <ModalHeader toggle={handleClose}>Available Rooms</ModalHeader>
  <ModalBody>
    {RoomsTypeList && RoomsTypeList.map((roomType, typeIndex) => (
      <Card key={typeIndex} className="my-3 shadow-sm">
        <CardBody>
          <h5 className="mb-4">{roomType.title.toUpperCase()} - {roomType.normalTariff}</h5>
          <Row>
            {RoomsList && RoomsList.filter(rooms => rooms.title === roomType.title).length > 0 ? (
              RoomsList.filter(rooms => rooms.title === roomType.title).map((rooms, roomIndex) => (
                <Col xs="12" sm="6" md="4" lg="3" key={roomIndex} className="mb-3 text-center">
                  <div className="available-room-card">
                    <h5 className="AvilableRoomButton" onClick={() => handleRoomSelect(rooms)}>
                      <span>{rooms.roomNo}</span> <br />
                      <span>{rooms.Id}</span>
                    </h5>
                  </div>
                </Col>
              ))
            ) : (
              <Col xs="12">
                <div className="text-danger text-center">
                  <h5>No Available Rooms</h5>
                </div>
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
    ))}
  </ModalBody>
  <ModalFooter>
    <Button color="secondary" onClick={handleClose}>
      Close
    </Button>
  </ModalFooter>
</Modal>

            <div className="mb-2">
            </div>
            <Row>
              <Col lg={8}>
                <Card>
                  <CardHeader><h5>Booking Details</h5></CardHeader>
                  <CardBody>
                    <div className="mb-2">
                      <Row className="align-items-center">
                        <Col lg={4} md={4} sm={4}>
                          <div><strong>Room Type:</strong>  {roomsDetails.roomTypeTitle ? roomsDetails.roomTypeTitle.toUpperCase() : 'N/A'} </div>
                        </Col>

                        <Col lg={4} md={4} sm={4}>
                          <strong>Room No:</strong>  {roomsDetails.roomNo || 'N/A'}
                        </Col>

                        <span style={{ display: 'none' }}>{roomsDetails.roomId}</span>

                        <Col lg={4} md={3} sm={3}>
                          <Button color="primary" onClick={handleShow}>Change Room</Button>
                        </Col>

                      </Row>
                      <Row className="mb-2">
                        <Col lg={4} md={4} sm={4}>
                          <strong>Single:</strong>{roomsDetails.singleTariff || 'N/A'}
                        </Col>

                        <Col lg={4} md={4} sm={2}>
                          <strong>Normal:</strong>{roomsDetails.normalTariff || 'N/A'}
                        </Col>
                        <Col lg={4} md={4} sm={2}>
                          <strong>Extra Person:</strong> {roomsDetails.extraPersonTariff || 'N/A'}
                        </Col>

                      </Row>
                    </div>


                  </CardBody>
                </Card>
                <Card>
                  <CardHeader><h5 className="">Guest Registration Form</h5></CardHeader>
                  <CardBody>
                    <div className="mb-2">
                      <Row>
                        <Row className="align-items-center">
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Mobile No</Label>
                                <Input
                                  name="mobile_no"
                                  type="text"
                                  placeholder="MobileNo"
                                  onChange={handleMobileSearch}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.mobile_no || ""}
                                  invalid={
                                    validation.touched.mobile_no &&
                                      validation.errors.mobile_no
                                      ? true
                                      : false
                                  }
                                  maxLength={10}
                                />
                                {validation.touched.mobile_no &&
                                  validation.errors.mobile_no ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.mobile_no}
                                  </FormFeedback>
                                ) : null}

                                {suggestions.length > 0 && (
                                  <ul className="suggestions-dropdown">
                                    {suggestions.map((suggestion, index) => (
                                      <li key={index} onClick={() => handleSuggestionClick(suggestion.mobileNo)}>
                                        {suggestion.mobileNo}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Email</Label>
                                <Input
                                  name="email"
                                  type="text"
                                  placeholder="Email"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email &&
                                      validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.email &&
                                  validation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={4} sm={4} md={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Guest Name</Label>
                                <Input
                                  name="guest_name"
                                  type="text"
                                  placeholder="Name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.guest_name || ""}
                                  invalid={
                                    validation.touched.guest_name &&
                                      validation.errors.guest_name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.guest_name &&
                                  validation.errors.guest_name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.guest_name}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Age</Label>
                                <Input
                                  name="age"
                                  type="text"
                                  placeholder="Age"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.age || ""}
                                  invalid={
                                    validation.touched.age &&
                                      validation.errors.age
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.age &&
                                  validation.errors.age ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.age}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Gender</Label>
                                <Select
                                  name="gender"
                                  value={selectedGender}
                                  onChange={handleGenderChange}
                                  placeholder="Select Gender"
                                  classNamePrefix="react-select"
                                  className={`react-select-container ${validation.touched.gender && validation.errors.gender
                                    ? 'is-invalid'
                                    : ''
                                    }`}
                                  options={genderOptions.map((gender) => ({
                                    label: gender.label,
                                    value: gender.value,
                                  }))}

                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      top: "100%",
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }),
                                  }}
                                  isClearable
                                  isSearchable
                                />
                                {validation.touched.gender && validation.errors.gender ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.gender}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Proof Type</Label>
                                <Select
                                  name="proofType"
                                  placeholder="Select Proof Type"
                                  classNamePrefix="react-select"
                                  className={`react-select-container ${validation.touched.proofType && validation.errors.proofType
                                    ? 'is-invalid'
                                    : ''
                                    }`}
                                  options={proofTypes.map((proof) => ({
                                    label: proof.identityName,
                                    value: proof.id,
                                  }))}
                                  value={selectedProofType}
                                  onChange={handleProofTypeChange}

                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      top: "100%",
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }),
                                  }}
                                  isClearable
                                  isSearchable
                                />
                                {validation.touched.proofType &&
                                  validation.errors.proofType ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.proofType}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Proof No.</Label>
                                <Input
                                  name="proof_no"
                                  type="text"
                                  placeholder={"Proof Number"}
                                  onChange={(e) => {
                                    e.target.value = e.target.value.toUpperCase();
                                    validation.handleChange(e);
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.proof_no || ""}
                                  invalid={
                                    validation.touched.proof_no &&
                                      validation.errors.proof_no
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.proof_no &&
                                  validation.errors.proof_no ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.proof_no}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Vehicle No.</Label>
                                <Input
                                  name="vehicle_no"
                                  type="text"
                                  placeholder="Vehicle No"
                                  onChange={(e) => {
                                    e.target.value = e.target.value.toUpperCase();
                                    validation.handleChange(e);
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.vehicle_no || ""}
                                  invalid={
                                    validation.touched.vehicle_no &&
                                      validation.errors.vehicle_no
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.vehicle_no &&
                                  validation.errors.vehicle_no ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.vehicle_no}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">GST Number</Label>
                                <Input
                                  name="gst_no"
                                  type="text"
                                  placeholder={"GST Number"}
                                  onChange={(e) => {
                                    e.target.value = e.target.value.toUpperCase();
                                    validation.handleChange(e);
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.gst_no || ""}
                                  invalid={
                                    validation.touched.gst_no &&
                                      validation.errors.gst_no
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.gst_no &&
                                  validation.errors.gst_no ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.gst_no}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Company Name</Label>
                                <Input
                                  name="company_name"
                                  type="text"
                                  placeholder="Company Name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.company_name || ""}
                                  invalid={
                                    validation.touched.company_name &&
                                      validation.errors.company_name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.company_name &&
                                  validation.errors.company_name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.company_name}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Wifi Password</Label>
                                <Select
                                  id="wifi"
                                  name="wifi"
                                  options={roomsList.map(type => ({ value: type.wifi, label: type.wifi }))}
                                  placeholder="Select a Wifi"
                                  value={selectedWifi}
                                  onChange={handlewifiChange}
                                  isClearable
                                  isSearchable
                                  classNamePrefix="custom-select"
                                  styles={{
                                    menu: provided => ({ ...provided, top: '100%', position: 'absolute', width: '100%', zIndex: 999 }),
                                  }}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.wifi && validation.errors.wifi
                                  }
                                />
                                {validation.touched.wifi && validation.errors.wifi && (
                                  <div className="text-danger">{validation.errors.wifi}</div>
                                )}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col xs={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Nationality</Label>
                                <Select
                                  name="nationality"

                                  value={NationalityOptions.find(option => option.value === selectedNationality)}
                                  onChange={handleNationality}
                                  placeholder="Select Nationality"
                                  classNamePrefix="react-select"
                                  className={`react-select-container ${validation.touched.nationality && validation.errors.nationality
                                    ? 'is-invalid'
                                    : ''
                                    }`}
                                  options={NationalityOptions.map((nationality) => ({
                                    label: nationality.label,
                                    value: nationality.value,
                                  }))}

                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      top: "100%",
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }),
                                  }}
                                />
                                {validation.touched.nationality && validation.errors.nationality ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.nationality}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>


                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Country</Label>
                                <Select
                                  name="country"
                                  placeholder="Select Country"
                                  classNamePrefix="react-select"
                                  className={`react-select-container ${validation.touched.country && validation.errors.country
                                    ? 'is-invalid'
                                    : ''
                                    }`}
                                  options={countriesList.map((country) => ({
                                    label: country.name,
                                    value: country,
                                  }))}
                                  value={selectedCountry}
                                  onChange={handleCountryChange}
                                  isClearable
                                  isSearchable
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      top: "100%",
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }),
                                  }}
                                />
                                {validation.touched.country &&
                                  validation.errors.country ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.country}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">State</Label>
                                <Select
                                  name="state"
                                  placeholder="Select State"
                                  classNamePrefix="react-select"
                                  className={`react-select-container ${validation.touched.state && validation.errors.state
                                    ? 'is-invalid'
                                    : ''
                                    }`}
                                  value={selectedState}
                                  onChange={handleStateChange}
                                  options={statesList.map((state) => ({
                                    label: state.name,
                                    value: state
                                  }))}
                                  isClearable
                                  isSearchable
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      top: "100%",
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }),
                                  }}
                                />
                                {validation.touched.state &&
                                  validation.errors.state ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.state}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">City</Label>
                                <Select
                                  name="city"
                                  placeholder="Select City"
                                  className={`react-select-containe ${validation.touched.city && validation.errors.city
                                    ? 'is-invalid'
                                    : ''
                                    }`}
                                  value={selectedCities}
                                  onChange={handleCityChange}
                                  options={citiesList.map((city) => ({
                                    label: city.name,
                                    value: city
                                  }))}
                                  isClearable
                                  isSearchable
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      top: "100%",
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }),
                                  }}
                                />
                                {validation.touched.city &&
                                  validation.errors.city ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.city}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>

                        </Row>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Address</Label>
                                <Input
                                  name="address"
                                  type="text"
                                  placeholder="Address"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.address || ""}
                                  invalid={
                                    validation.touched.address &&
                                      validation.errors.address
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.address &&
                                  validation.errors.address ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.address}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Pin Code</Label>
                                <Input
                                  name="pincode"
                                  type="text"
                                  placeholder="Pin Code"
                                  onChange={(e) => {
                                    const re = /^[0-9\b]+$/;
                                    if (e.target.value === '' || re.test(e.target.value)) {
                                      validation.handleChange(e);
                                    }
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.pincode || ""}
                                  invalid={
                                    validation.touched.pincode &&
                                      validation.errors.pincode
                                      ? true
                                      : false
                                  }
                                  maxLength={6}
                                />
                                {validation.touched.pincode &&
                                  validation.errors.pincode ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.pincode}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="form-label">Upload Image</Label>
                                <Input
                                  name="proof_photo"
                                  type="file"
                                  accept="image/*"
                                  className={`form-control`}
                                  onChange={handleFileChange}
                                  multiple
                                />
                                {validation.touched.proof_photo &&
                                  validation.errors.proof_photo ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.proof_photo}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                        <Row>
  {filePreviews.length > 0 ? (
    filePreviews.map((preview, index) => (
      <Col key={index} xs={12} sm={6} className="mb-3">
        <img
          src={preview}
          alt={`Proof photo ${index}`}
          className="img-thumbnail"
          style={{ width: '100%', height: 'auto' }}
        />
      </Col>
    ))
  ) : (
    <p>No proof photos available</p>
  )}
</Row>

                        {/* Display selected images below */}
                        {/* <Row>
                          {filePreviews.map((preview, index) => (
                            <Col key={index} xs={12} sm={6} className="mb-3">
                              <img
                                src={preview}
                                alt={`Selected file ${index}`}
                                className="img-thumbnail"
                                style={{ width: "100%", height: "auto" }}
                              />
                            </Col>
                          ))}
                        </Row> */}
                      </Row>

                    </div>
                  </CardBody>
                </Card>

              </Col>
              <Col lg={4}>
                <Card>
                  <CardHeader><h5>Check In Info</h5></CardHeader>
                  <CardBody>
                    <div className="mb-1">
                      <Row>
                        <Col xs={12}>
                          <div className="mb-3" style={{ position: 'relative' }}>
                            <label htmlFor="testName" className="form-label"> Select Room Type </label>
                            <Select

                              name="roomTypeIds"
                              options={roomTypeList.map(type => ({ value: type.roomTypeId, label: type.title }))}
                              placeholder="Select a Room Type"
                              value={selectedRoomType}
                              onChange={handleRoomChange}
                              // isClearable
                              isSearchable
                              classNamePrefix="react-select"
                              className={`react-select-container ${validation.touched.roomTypeIds && validation.errors.roomTypeIds
                                ? 'is-invalid'
                                : ''
                                }`}
                              styles={{
                                menu: provided => ({ ...provided, top: '100%', position: 'absolute', width: '100%', zIndex: 999 }),
                              }}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.roomTypeIds && validation.errors.roomTypeIds
                              }
                            />
                            {validation.touched.roomTypeIds && validation.errors.roomTypeIds && (
                              <div className="text-danger">{validation.errors.roomTypeIds}</div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label for="checkInDate" className="form-label">Check-In Date</Label>
                              <DatePicker
                                name="checkInDate"
                                selected={checkInDate}
                                onChange={handleCheckInDateChange}
                                dateFormat="dd-MM-yyyy"
                                className="form-control"
                                placeholderText="Select a date"
                                minDate={new Date()}
                              />
                              <FontAwesomeIcon icon={faCalendarAlt}
                                className="position-absolute"
                                style={{ top: '56%', right: '23px', transform: 'translateY(-50%)', color: '#6c757d' }}
                              />

                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="form-label">Expected Check-Out Date</Label>
                              <DatePicker
                                name="checkoutDate"
                                selected={checkOutDate}
                                onChange={handleCheckOutDateChange}
                                dateFormat="dd-MM-yyyy"
                                className="form-control"
                                placeholderText="Select a date"
                                minDate={addDays(new Date(), 1)}
                              />
                              <FontAwesomeIcon icon={faCalendarAlt}
                                className="position-absolute"
                                style={{ top: '56%', right: '23px', transform: 'translateY(-50%)', color: '#6c757d' }}
                              />

                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Male">Male</label>
                            <Input
                              type="number"
                              name="no_of_male"
                              placeholder="0"
                              min={0}
                              value={validation.values.no_of_male || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.no_of_male && validation.errors.no_of_male}
                            />
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="FeMale">Female</label>
                            <Input
                              type="number"
                              name="no_of_female"
                              placeholder="0"
                              min={0}
                              value={validation.values.no_of_female || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.no_of_female && validation.errors.no_of_female}
                            />
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Male">Child</label>
                            <Input
                              type="number"
                              name="no_of_child"
                              placeholder="0"
                              min={0}
                              value={validation.values.no_of_child || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.no_of_child && validation.errors.no_of_child}
                            />
                          </div>
                        </Col>
                        {showCommonError() && (
                          <FormFeedback className="d-block text-danger text-center">
                            {showCommonError()}
                          </FormFeedback>
                        )}
                      </Row>
                      {/* Render child age inputs based on number of children */}
                      {validation.values.no_of_child > 0 && renderChildrenAges()}

                      <Row>
                        <Col xs={6}>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="tariff">Tariff</label>
                            <Input
                              type="number"
                              name="normaltariff"
                              placeholder="0.00"
                              min={0}
                              value={validation.values.normaltariff || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.normaltariff && validation.errors.normaltariff}
                            />
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="AdvanceAmt">Advance Amount</label>
                            <Input
                              type="number"
                              name="advance_Amt"
                              placeholder="0"
                              min={0}
                              value={validation.values.advance_Amt || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.advance_Amt && validation.errors.advance_Amt}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <div className="mb-3" style={{ position: 'relative' }}>
                            <label htmlFor="testName" className="form-label">Select Payment Type </label>
                            <Select
                              name="payment"
                              placeholder="Select Payment Type"
                              className={`react-select-containe ${validation.touched.payment && validation.errors.payment
                                ? 'is-invalid'
                                : ''
                                }`}
                              value={selectedPayment}
                              onChange={handlePaymentTypeChange}
                              options={paymentTypeList.map((payment) => ({
                                label: payment.paymentType,
                                value: payment.id
                              }))}
                              isClearable
                              isSearchable
                              styles={{
                                menu: (provided) => ({
                                  ...provided,
                                  top: "100%",
                                  position: "absolute",
                                  width: "100%",
                                  zIndex: 999,
                                }),
                              }}
                            />
                            {validation.touched.payment &&
                              validation.errors.payment ? (
                              <FormFeedback type="invalid">
                                {validation.errors.payment}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <div className="mb-3">
                            <Label className="form-label">Remarks</Label>
                            <Input
                              name="remarks"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.remarks || ""}
                              invalid={
                                validation.touched.remarks &&
                                  validation.errors.remarks
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.remarks &&
                              validation.errors.remarks ? (
                              <FormFeedback type="invalid">
                                {validation.errors.remarks}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Reference By
                            </Label>
                            <Input
                              name="reference_by"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.reference_by || ""}
                              invalid={
                                validation.touched.reference_by &&
                                  validation.errors.reference_by
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.reference_by &&
                              validation.errors.reference_by ? (
                              <FormFeedback type="invalid">
                                {validation.errors.reference_by}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <div className="d-flex justify-content-center">
                            <Button color="btn btn-success" type="submit" className="mx-2">
                              Check In
                            </Button>
                            <Button color="secondary" className="mx-2" onClick={() => handleBackClick()}>
                              Back
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default BookingCheckIn;
